import React, { useEffect, useState } from 'react';
import Select, {
  components,
  ActionMeta,
  OnChangeValue,
  MultiValue,
  OptionProps,
  SingleValue,
} from 'react-select';
import makeAnimated from 'react-select/animated';
import {
  Image,
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  Input,
} from '@chakra-ui/react';
import {
  CustomField,
  CustomFieldType,
  EntitiesEnum,
  getConditionsByCustomFieldType,
  getSpeCustomFieldsByEntity,
  OptionType,
  RuleCondition,
  getMultiSelOptions,
} from '../../services/queryBuilder';
import { getPhoneFormat, getNumFormat } from '../../utils/formatters';

type RuleRowProps = {
  row?: RuleRowType;
  disableOr: boolean;
  entitiesToQueryBy: EntitiesEnum[];
  fromPurchases: boolean;
  skuOptions?: OptionType[];
  onUpdateResults: () => void;
  onAddRuleRow: () => void;
  onRemoveRuleRow: () => void;
  onRuleRowUpdate: (ruleRow: RuleRowType) => void;
};

export type RuleRowType = {
  id?: string;
  field: CustomField | null;
  condition: RuleCondition | '';
  value1: string;
  value2?: string;
};

const RuleRow = ({
  row,
  disableOr,
  entitiesToQueryBy,
  fromPurchases,
  skuOptions,
  onUpdateResults,
  onAddRuleRow,
  onRemoveRuleRow,
  onRuleRowUpdate,
}: RuleRowProps) => {
  const initialCustomFields = getSpeCustomFieldsByEntity(
    entitiesToQueryBy[0],
    fromPurchases,
  );
  const [availableConditions, setAvailableConditions] = useState<OptionType[]>(
    getConditionsByCustomFieldType(
      row?.field ? row.field : initialCustomFields[0],
      fromPurchases,
    ),
  );
  const [selectedCustomField, setSelectedCustomField] =
    useState<CustomField | null>(initialCustomFields[0]);
  const [selectedCondition, setSelectedCondition] = useState<
    RuleCondition | ''
  >('');
  const [isOrBtnDisabled, setIsOrBtnDisabled] = useState<boolean>(
    disableOr || false,
  );
  const [showRemoveButton, setShowRemoveButton] = useState<boolean>(false);
  const [value1, setValue1] = useState<string | null>(row?.value1 || null);
  const [value2, setValue2] = useState<string | null>(row?.value2 || null);
  const isError1 = value1 === '';
  const isError2 = value2 === '';
  const animatedComponents = makeAnimated();
  const purchasedOptions = [
    { label: 'Purchased', value: '0' },
    { label: 'Did Not Purchase', value: '1' },
  ];

  const handleCustomFieldChange = async (
    newValue: OnChangeValue<OptionType, false>,
    actionMeta: ActionMeta<OptionType>,
  ) => {
    const selectedValue = newValue?.value;
    if (!selectedValue) {
      setSelectedCustomField(null);
      return;
    }

    const type = selectedValue.split('.')[0];
    const key = selectedValue.split('.')[1];

    if (
      !type ||
      !key ||
      (type == row?.field?.entity && key == row?.field?.key)
    ) {
      return;
    }

    let customField: CustomField | undefined = getSpeCustomFieldsByEntity(
      type as EntitiesEnum,
      fromPurchases,
    ).find((customField) => customField.key === key);

    if (!customField) {
      return;
    }

    if (customField.asyncOptions) {
      const options = await customField.asyncOptions();
      customField = {
        ...customField,
        options,
      };
    }

    setSelectedCustomField(customField);
    const conditionsByType = getConditionsByCustomFieldType(
      customField,
      fromPurchases,
    );
    setAvailableConditions(conditionsByType);

    const firstCondition = conditionsByType[0].value as RuleCondition;
    let selectedCondition = firstCondition;
    if (key === 'purchased') {
      selectedCondition = RuleCondition.MATCHES_EXACTLY;
    } else if (key === 'not_purchased') {
      selectedCondition = RuleCondition.DOES_NOT_MATCH_EXACTLY;
    }
    setSelectedCondition(selectedCondition);
    onRuleRowUpdate({
      field: customField,
      condition: selectedCondition,
      value1: '',
      value2: '',
    });
  };

  const handleConditionChange = (
    newValue: OnChangeValue<OptionType, false>,
    actionMeta: ActionMeta<OptionType>,
  ) => {
    const condition = newValue?.value as RuleCondition;
    setSelectedCondition(condition);
    if (
      [
        RuleCondition.IS_EMPTY,
        RuleCondition.IS_NOT_PROVIDED,
        RuleCondition.IS_PROVIDED,
        RuleCondition.YESTERDAY,
        RuleCondition.LAST_7_DAYS,
        RuleCondition.LAST_30_DAYS,
        RuleCondition.THIS_MONTH,
        RuleCondition.LAST_MONTH,
        RuleCondition.YEAR_TO_DATE,
        RuleCondition.LAST_12_MONTHS,
        RuleCondition.LAST_YEAR,
      ].includes(condition)
    ) {
      setValue1(null);
      setValue2(null);
    }
    onRuleRowUpdate({
      field: selectedCustomField,
      condition: condition || '',
      value1: value1 || '',
      value2: value2 || '',
    });
  };

  const handleAddOrCondition = () => {
    onAddRuleRow();
  };

  const handleRemoveRow = () => {
    onRemoveRuleRow();
  };

  const handleInput1Change = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    let inputValue = e.target.value;
    if (
      selectedCustomField?.entity == EntitiesEnum.CUSTOMERS &&
      selectedCustomField?.type == CustomFieldType.TEXT &&
      selectedCustomField?.label.includes('Phone') &&
      (selectedCondition == RuleCondition.MATCHES_EXACTLY ||
        selectedCondition == RuleCondition.DOES_NOT_MATCH_EXACTLY ||
        selectedCondition == RuleCondition.STARTS_WITH ||
        selectedCondition == RuleCondition.DOES_NOT_START_WITH)
    ) {
      inputValue = getPhoneFormat(inputValue);
    } else if (
      selectedCustomField?.type === CustomFieldType.NUMBER ||
      (selectedCondition &&
        [
          RuleCondition.BEFORE_LAST_X_DAYS,
          RuleCondition.BEFORE_LAST_X_MONTHS,
          RuleCondition.BEFORE_LAST_X_YEARS,
        ].includes(selectedCondition))
    ) {
      inputValue = getNumFormat(inputValue);
    }
    setValue1(inputValue);
    onRuleRowUpdate({
      field: selectedCustomField,
      condition: selectedCondition || '',
      value1: inputValue || '',
      value2: value2 || '',
    });
  };

  const handleInput2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;
    if (selectedCustomField?.type === CustomFieldType.NUMBER) {
      inputValue = getNumFormat(inputValue);
    }
    setValue2(inputValue);
    onRuleRowUpdate({
      field: selectedCustomField,
      condition: selectedCondition || '',
      value1: value1 || '',
      value2: inputValue || '',
    });
  };

  const handleSingleSelectChange = (
    newValue: SingleValue<OptionType> | MultiValue<OptionType>,
    actionMeta: ActionMeta<OptionType>,
  ) => {
    newValue = newValue as SingleValue<OptionType>;
    setValue1(newValue?.value || '');
    onRuleRowUpdate({
      field: selectedCustomField,
      condition: selectedCondition || '',
      value1: newValue?.value || '',
      value2: value2 || '',
    });
  };

  const handleSkuSelectChange = (
    newValue: OnChangeValue<OptionType, false>,
    actionMeta: ActionMeta<OptionType>,
  ) => {
    setValue2(newValue?.value || '');
    let sc: '' | RuleCondition = '';
    if (newValue?.value == '1') {
      console.log('setSelectedCondition(RuleCondition.DOES_NOT_MATCH_EXACTLY)');
      sc = RuleCondition.DOES_NOT_MATCH_EXACTLY;
      setSelectedCondition(sc);
    } else {
      console.log('setSelectedCondition(RuleCondition.MATCHES_EXACTLY)');
      sc = RuleCondition.MATCHES_EXACTLY;
      setSelectedCondition(sc);
    }
    onRuleRowUpdate({
      field: selectedCustomField,
      condition: sc || '',
      value1: value1 || '',
      value2: newValue?.value || '',
    });
  };

  const handleMultiSelectChange = (
    newValue: MultiValue<OptionType>,
    actionMeta: ActionMeta<OptionType>,
  ) => {
    let value = '';
    newValue.map(
      (option) =>
        (value += value.length > 0 ? '|' + option.value : option.value),
    );
    setValue1(value);
    onRuleRowUpdate({
      field: selectedCustomField,
      condition: selectedCondition || '',
      value1: value,
      value2: value2 || '',
    });
  };

  const handleOnRowHover = () => {
    setShowRemoveButton(true);
  };

  const handleOnRowLeave = () => {
    setShowRemoveButton(false);
  };

  const handleInitCustomField = async (row: RuleRowType | undefined) => {
    if (row && row.field) {
      if (
        row.field.asyncOptions &&
        (!row.field.options || row.field.options?.length == 0)
      ) {
        const options = await row.field.asyncOptions();
        row.field = {
          ...row.field,
          options,
        };
      }
      setSelectedCustomField(row.field);
      setSelectedCondition(row.condition);

      const conditionsByType = getConditionsByCustomFieldType(
        row.field,
        fromPurchases,
      );
      setAvailableConditions(conditionsByType);

      setValue1(row.value1);
      setValue2(row.value2 || null);
    } else if (fromPurchases) {
      setSelectedCustomField(initialCustomFields[0]);
      setSelectedCondition(RuleCondition.MATCHES_EXACTLY);
      setValue1(null);
      setValue2('0');
    } else {
      setSelectedCustomField(null);
      setValue1(null);
      setValue2(null);
    }
  };

  const { Option } = components;

  const purchaseOption = (props: OptionProps<OptionType>) => {
    const { value, label } = props.data;
    const getName = () => {
      return label.substring(
        label.indexOf(' - ') + 3,
        label.lastIndexOf(' - '),
      );
    };
    const getFormat = () => {
      return label.lastIndexOf(' - ') < label.length - 3
        ? label.substring(label.lastIndexOf(' - ') + 3)
        : '';
    };

    return (
      <Option {...props}>
        <div>
          <b>{value}</b> - <i>{getName()}</i> - {getFormat()}
        </div>
      </Option>
    );
  };

  useEffect(() => {
    if (!selectedCondition || !selectedCustomField || disableOr) {
      setIsOrBtnDisabled(true);
      return;
    }

    if (
      selectedCondition !== RuleCondition.IS_PROVIDED &&
      selectedCondition !== RuleCondition.IS_NOT_PROVIDED &&
      !value1
    ) {
      setIsOrBtnDisabled(true);
      return;
    }

    if (selectedCondition === RuleCondition.IS_BETWEEN && !value2) {
      setIsOrBtnDisabled(true);
      return;
    }

    setIsOrBtnDisabled(false);
  }, [selectedCondition, selectedCustomField, value1, value2, disableOr]);

  useEffect(() => {
    handleInitCustomField(row);
  }, [row, initialCustomFields]);

  return (
    <Box
      textAlign={'left'}
      onMouseOver={handleOnRowHover}
      onMouseLeave={handleOnRowLeave}
      py={'12px'}
    >
      {selectedCustomField && !fromPurchases ? (
        <Badge>{selectedCustomField?.entity}</Badge>
      ) : (
        <Box width={'100%'} height={'5px'} />
      )}
      <Flex gridGap={4} justifyContent={'flex-start'} mt={'3px'}>
        {/* Available Conditions */}
        {fromPurchases && (
          <Flex>
            <FormControl minWidth={250} maxWidth={320} mb={'8px'}>
              <Select
                placeholder="Select Purchases"
                value={purchasedOptions?.find((c) => c.value === value2)}
                options={purchasedOptions?.map((purchasedOption) => ({
                  value: purchasedOption.value,
                  label: purchasedOption.label,
                }))}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: '14px',
                    minHeight: '40px',
                  }),
                  option: (styles, { isSelected, isFocused }) => ({
                    ...styles,
                    backgroundColor: isSelected
                      ? '#FFB9AC'
                      : isFocused
                        ? '#E7EAEC'
                        : 'white',
                    color: '#2A2F56',
                    fontSize: '14px',
                  }),
                }}
                onChange={handleSkuSelectChange}
              />
            </FormControl>
          </Flex>
        )}
        {/* Available Customfields */}
        {!fromPurchases && (
          <Flex>
            <FormControl minWidth={250} maxWidth={320} mb={'8px'}>
              <Select
                value={
                  selectedCustomField
                    ? {
                        value: `${selectedCustomField?.entity}.${selectedCustomField?.key}`,
                        label: `${selectedCustomField?.label}`,
                      }
                    : {
                        value: ``,
                        label: `Select …`,
                      }
                }
                options={entitiesToQueryBy.map((entity) => ({
                  label: `${entity.toUpperCase()}`,
                  options: getSpeCustomFieldsByEntity(
                    entity,
                    fromPurchases,
                  ).map((customField) => ({
                    value: `${customField.entity}.${customField.key}`,
                    label: customField.label,
                  })),
                }))}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: '14px',
                    minHeight: '40px',
                  }),
                  option: (styles, { isSelected, isFocused }) => ({
                    ...styles,
                    backgroundColor: isSelected
                      ? '#FFB9AC'
                      : isFocused
                        ? '#E7EAEC'
                        : 'white',
                    color: '#2A2F56',
                    fontSize: '14px',
                    paddingLeft: '24px',
                  }),
                  group: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: '13px',
                  }),
                }}
                onChange={handleCustomFieldChange}
              />
            </FormControl>
          </Flex>
        )}
        {/* Available Conditions */}
        {!fromPurchases &&
          selectedCustomField?.key !== 'purchased' &&
          selectedCustomField?.key !== 'not_purchased' &&
          selectedCustomField && (
            <Flex>
              <FormControl minWidth={250} maxWidth={320} mb={'8px'}>
                <Select
                  value={availableConditions.find(
                    (c) => c.value === selectedCondition,
                  )}
                  options={availableConditions.map((customField) => ({
                    value: customField.value,
                    label: customField.label,
                  }))}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: '14px',
                      minHeight: '40px',
                    }),
                    option: (styles, { isSelected, isFocused }) => ({
                      ...styles,
                      backgroundColor: isSelected
                        ? '#FFB9AC'
                        : isFocused
                          ? '#E7EAEC'
                          : 'white',
                      color: '#2A2F56',
                      fontSize: '14px',
                      paddingLeft: '24px',
                    }),
                  }}
                  onChange={handleConditionChange}
                />
              </FormControl>
            </Flex>
          )}
        {/* Value 1 */}
        {(selectedCondition === '' ||
          ![
            RuleCondition.IS_EMPTY,
            RuleCondition.IS_NOT_PROVIDED,
            RuleCondition.IS_PROVIDED,
            RuleCondition.YESTERDAY,
            RuleCondition.LAST_7_DAYS,
            RuleCondition.LAST_30_DAYS,
            RuleCondition.THIS_MONTH,
            RuleCondition.LAST_MONTH,
            RuleCondition.YEAR_TO_DATE,
            RuleCondition.LAST_12_MONTHS,
            RuleCondition.LAST_YEAR,
          ].includes(selectedCondition) ||
          selectedCustomField?.key === 'purchased' ||
          selectedCustomField?.key === 'not_purchased') && (
          <Flex>
            {(selectedCustomField?.type === CustomFieldType.TEXT ||
              selectedCustomField?.type === CustomFieldType.NUMBER) && (
              <FormControl
                isInvalid={isError1}
                minWidth={
                  selectedCondition === RuleCondition.IS_BETWEEN ? 200 : 250
                }
              >
                <Input
                  required
                  value={value1 || ''}
                  onChange={handleInput1Change}
                  height={'40px'}
                  fontSize={'14px'}
                  borderColor={'#cccccc'}
                  type={'text'}
                  placeholder="Value"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onUpdateResults();
                    }
                  }}
                />
              </FormControl>
            )}
            {selectedCustomField?.type === CustomFieldType.DATE && (
              <FormControl
                isInvalid={isError1}
                minWidth={
                  selectedCondition === RuleCondition.IS_BETWEEN ? 200 : 250
                }
              >
                {selectedCondition &&
                [
                  RuleCondition.BEFORE_LAST_X_DAYS,
                  RuleCondition.BEFORE_LAST_X_MONTHS,
                  RuleCondition.BEFORE_LAST_X_YEARS,
                ].includes(selectedCondition) ? (
                  <Input
                    required
                    value={value1 || ''}
                    onChange={handleInput1Change}
                    height={'40px'}
                    fontSize={'14px'}
                    borderColor={'#cccccc'}
                    type={'text'}
                    placeholder="Value"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onUpdateResults();
                      }
                    }}
                  />
                ) : (
                  <Input
                    required
                    value={value1 || ''}
                    onChange={handleInput1Change}
                    height={'40px'}
                    fontSize={'14px'}
                    borderColor={'#cccccc'}
                    type={'date'}
                    placeholder="Date"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onUpdateResults();
                      }
                    }}
                  />
                )}
              </FormControl>
            )}
            {selectedCondition === RuleCondition.IS_BETWEEN && (
              <FormControl isInvalid={isError2} minWidth={200} ml={'16px'}>
                <Input
                  value={value2 || ''}
                  required
                  height={'40px'}
                  fontSize={'14px'}
                  borderColor={'#cccccc'}
                  type={
                    selectedCustomField?.type === CustomFieldType.DATE
                      ? 'date'
                      : 'text'
                  }
                  placeholder={
                    selectedCustomField?.type === CustomFieldType.DATE
                      ? 'Date'
                      : 'Value'
                  }
                  onChange={handleInput2Change}
                  onSubmit={onUpdateResults}
                />
              </FormControl>
            )}
            {!fromPurchases &&
            selectedCustomField?.key !== 'purchased' &&
            selectedCustomField?.key !== 'not_purchased'
              ? (selectedCustomField?.type === CustomFieldType.OPTIONS_SINGLE ||
                  selectedCustomField?.type === CustomFieldType.BOOLEAN) && (
                  <FormControl minWidth={250} maxWidth={320} mb={'8px'}>
                    <Select
                      value={
                        value1
                          ? selectedCustomField?.options?.find(
                              (c) =>
                                c.value.toLowerCase() === value1?.toLowerCase(),
                            )
                          : null
                      }
                      options={selectedCustomField?.options?.map(
                        (customField) => ({
                          value: customField.value,
                          label: customField.label,
                        }),
                      )}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          fontSize: '14px',
                          minHeight: '40px',
                        }),
                        option: (styles, { isSelected, isFocused }) => ({
                          ...styles,
                          backgroundColor: isSelected
                            ? '#FFB9AC'
                            : isFocused
                              ? '#E7EAEC'
                              : 'white',
                          color: '#2A2F56',
                          fontSize: '14px',
                          paddingLeft: '24px',
                        }),
                      }}
                      onChange={handleSingleSelectChange}
                    />
                  </FormControl>
                )
              : (selectedCustomField?.type === CustomFieldType.OPTIONS_SINGLE ||
                  selectedCustomField?.type === CustomFieldType.BOOLEAN) && (
                  <FormControl minWidth={720} maxWidth={840} mb={'8px'}>
                    <Select
                      value={
                        value1
                          ? skuOptions?.find(
                              (c) =>
                                c.value.toLowerCase() === value1?.toLowerCase(),
                            )
                          : null
                      }
                      options={skuOptions}
                      components={{ Option: purchaseOption }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          fontSize: '14px',
                          minHeight: '40px',
                        }),
                        option: (styles, { isSelected, isFocused }) => ({
                          ...styles,
                          backgroundColor: isSelected
                            ? '#FFB9AC'
                            : isFocused
                              ? '#E7EAEC'
                              : 'white',
                          color: '#2A2F56',
                          fontSize: '14px',
                          paddingLeft: '24px',
                        }),
                      }}
                      onChange={handleSingleSelectChange}
                    />
                  </FormControl>
                )}

            {selectedCustomField?.type === CustomFieldType.OPTIONS_MULTIPLE &&
              (selectedCondition === RuleCondition.IN ||
                selectedCondition === RuleCondition.NOT_IN) && (
                <FormControl minWidth={250} maxWidth={320} mb={'8px'}>
                  <Select
                    value={getMultiSelOptions(
                      selectedCustomField.options || [],
                      value1 || '',
                    )}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={selectedCustomField.options || []}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '14px',
                        minHeight: '40px',
                      }),
                      option: (styles, { isSelected, isFocused }) => ({
                        ...styles,
                        backgroundColor: isSelected
                          ? '#FFB9AC'
                          : isFocused
                            ? '#E7EAEC'
                            : 'white',
                        color: '#2A2F56',
                        fontSize: '14px',
                        paddingLeft: '24px',
                      }),
                    }}
                    onChange={handleMultiSelectChange}
                  />
                </FormControl>
              )}
          </Flex>
        )}
        <Flex width={'100%'} justifyContent={'end'}>
          <Button
            minWidth={'60px'}
            px={'12px'}
            fontSize={'14px'}
            variant={'offset-rule'}
            leftIcon={<Image width={'16px'} src="/ic_add_gray.png" />}
            disabled={isOrBtnDisabled}
            onClick={handleAddOrCondition}
          >
            Or
          </Button>
          <Image
            width={'16px'}
            height={'16px'}
            src="/ic_delete.png"
            ml={'14px'}
            mt={'13px'}
            cursor={'pointer'}
            onClick={handleRemoveRow}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default RuleRow;
