import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex, Heading } from '@chakra-ui/layout';
import {
  deleteSegment,
  getSegments,
  SegmentType,
} from '../../services/segments';
import {
  Button,
  Image,
  Link,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  Text,
} from '@chakra-ui/react';
import { Link as RouterDomLink } from 'react-router-dom';
import { pullLastUpdatedCustomersFromOffset } from '../../services/customers';
import { useToken, judgeUserInfo } from '../../services/auth';
import { getTimeAgo } from '../../utils/formatters';
import { pullProductsFromOffset } from '../../services/products';
import { EntitiesEnum } from '../../services/queryBuilder';
import { getLastUpdate } from '../../services/common';
import { pullOrdersFromOffset } from '../../services/orders';
import NavBar from '../../components/NavBar/NavBar';
import { PAGE_INDEX_SEGMENTS } from '../../utils/constants';

type SegmentListProps = {
  entityType: EntitiesEnum;
  signOut: () => void;
  setPageIndex: (pageIndex: number) => void;
};

const SegmentList = ({
  entityType,
  signOut,
  setPageIndex,
}: SegmentListProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [segments, setSegments] = useState<SegmentType[]>([]);
  const [lastUpdate, setLastUpdate] = useState<Date | null>(null);
  const [hasReportingToken, setHasReportingToken] = useState<boolean>(true);
  const [loadFinished, setLoadFinished] = useState<boolean>(false);

  const toast = useToast();
  const { userInfo } = useToken();

  const judgeExpired = () => {
    let isExpired = true;
    const loginTimeStr = localStorage.getItem('login_time');
    if (loginTimeStr && loginTimeStr.length > 0) {
      const loginTime = Number.parseInt(loginTimeStr);
      const currentTime = new Date().getTime();
      isExpired = currentTime - loginTime > 3 * 24 * 3600 * 1000;
    }
    if (isExpired) {
      signOut();
    }

    return isExpired;
  };

  const loadSegments = useCallback(async () => {
    try {
      if (judgeExpired()) {
        return;
      }

      setIsLoading(true);
      setLoadFinished(false);
      const segments = await getSegments(
        userInfo?.currentAccount?.id,
        entityType,
      );
      setSegments(segments);
      setLoadFinished(true);
    } catch (error) {
      if ((error as any).data.code === 'NO_REPORTING_TOKEN') {
        toast({
          title: 'No reporting token',
          description: 'Please, add a reporting token to your Offset account',
          position: 'top',
          status: 'error',
          duration: 2000,
          isClosable: false,
        });
        setHasReportingToken(false);
      }
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [userInfo?.currentAccount?.id, toast, entityType]);

  const updateFromSource = async () => {
    try {
      setIsLoading(true);

      if (entityType === EntitiesEnum.CUSTOMERS) {
        await pullLastUpdatedCustomersFromOffset(userInfo?.currentAccount?.id);
        await loadLastSyncedAt();
      } else if (entityType === EntitiesEnum.PRODUCTS) {
        await pullProductsFromOffset(userInfo?.currentAccount?.id);
        await loadLastSyncedAt();
      } else if (entityType === EntitiesEnum.ORDERS) {
        await pullOrdersFromOffset(userInfo?.currentAccount?.id);
        await loadLastSyncedAt();
      }

      toast({
        title: 'Synced with Offset API!',
        position: 'top',
        status: 'success',
        duration: 2000,
        isClosable: false,
      });
    } catch (error) {
      console.log(error);
      toast({
        title: (error as Error).message,
        position: 'top',
        status: 'error',
        duration: 2000,
        isClosable: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const loadLastSyncedAt = useCallback(async () => {
    try {
      if (!userInfo?.currentAccount) throw new Error('No current account');

      const lastUpdateFromApi = await getLastUpdate(
        entityType,
        userInfo?.currentAccount?.id,
      );
      setLastUpdate(lastUpdateFromApi);
    } catch (error) {
      console.log(error);
    }
  }, [userInfo?.currentAccount?.id, entityType]);

  const handleDeleteSegment = async (segmentId: string) => {
    if (
      window.confirm('Are you sure you want to delete this segment?') === false
    ) {
      return;
    }

    try {
      setIsLoading(true);
      await deleteSegment(segmentId);
      await loadSegments();
      toast({
        title: 'Segment deleted!',
        position: 'top',
        status: 'success',
        duration: 2000,
        isClosable: false,
      });
    } catch (error) {
      console.log(error);
      toast({
        title: (error as Error).message,
        position: 'top',
        status: 'error',
        duration: 2000,
        isClosable: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!judgeExpired()) {
      if (!judgeUserInfo()) {
        window.location.reload();
      } else {
        setPageIndex(PAGE_INDEX_SEGMENTS);
        loadSegments();
        if (entityType !== EntitiesEnum.PURCHASES) {
          loadLastSyncedAt();
        }
      }
    }
  }, [loadSegments, loadLastSyncedAt]);

  return (
    <Flex direction={'column'} height={'100%'} pr={'30px'}>
      <NavBar
        removeToken={() => signOut()}
        entityType={entityType}
        paramName={'segs'}
      />
      <Flex
        direction={'column'}
        backgroundColor={'#f3f4f5'}
        height={'100%'}
        borderRadius={'4px'}
      >
        {!hasReportingToken &&
        userInfo?.currentAccount?.id &&
        userInfo.accounts ? (
          <Text
            px={'20px'}
            mt={6}
            fontSize={'20px'}
            lineHeight={'46px'}
            color={'red.500'}
          >
            Please, add a reporting token for your Offset account "
            {userInfo?.currentAccount?.name}"
          </Text>
        ) : (
          <>
            <Flex
              px={'24px'}
              mt={8}
              gridGap={2}
              justifyContent={'space-between'}
            >
              <Heading
                lineHeight={'46px'}
                fontSize={'25px'}
                fontFamily={'Beatrice'}
              >
                {entityType === EntitiesEnum.CUSTOMERS
                  ? 'Customer Queries'
                  : entityType === EntitiesEnum.ORDERS
                    ? 'Order Queries'
                    : entityType === EntitiesEnum.PRODUCTS
                      ? 'Product Queries'
                      : 'Purchases Queries'}
              </Heading>
              {entityType != EntitiesEnum.PURCHASES && (
                <Text ml={'auto'} fontSize={'14px'} lineHeight={'46px'}>
                  {lastUpdate ? 'Last Sync: ' + getTimeAgo(lastUpdate) : ''}
                </Text>
              )}
              {entityType != EntitiesEnum.PURCHASES && (
                <Button
                  variant="offset-export"
                  fontSize={'14px'}
                  disabled={isLoading}
                  ml={'12px'}
                  onClick={updateFromSource}
                >
                  Sync Now
                </Button>
              )}
              <Link
                _hover={{ textDecor: 'none' }}
                as={RouterDomLink}
                to={`/segs/${entityType}/new`}
              >
                <Button fontSize={'14px'}>Create Query</Button>
              </Link>
            </Flex>
            <Box w={'100%'} overflowX="scroll">
              {isLoading ? (
                <Flex
                  h={'300px'}
                  w={'100%'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Spinner color="orange.100" />
                </Flex>
              ) : (
                loadFinished && (
                  <TableContainer
                    mt={6}
                    mx={'24px'}
                    borderWidth={'1px'}
                    borderColor={'#DEE2E6'}
                    borderRadius={'6px'}
                  >
                    <Table
                      colorScheme={'blackAlpha'}
                      backgroundColor={'white'}
                      size="md"
                    >
                      <Thead>
                        <Tr>
                          <Th
                            pl={'24px'}
                            py={'16px'}
                            textColor={'#4F5268'}
                            fontSize={'13px'}
                          >
                            ID
                          </Th>
                          <Th textColor={'#4F5268'} fontSize={'13px'}>
                            Name
                          </Th>
                          <Th textColor={'#4F5268'} fontSize={'13px'}>
                            Actions
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {segments.map((segment: SegmentType, i) => (
                          <Tr key={i}>
                            <Td
                              pl={'24px'}
                              width={'10%'}
                              textColor={'#2A2F56'}
                              fontSize={'14px'}
                            >
                              {segment.id}
                            </Td>
                            <Td width={'80%'}>
                              <Link
                                as={RouterDomLink}
                                to={`/segs/${entityType}/${segment.id}`}
                                textColor={'#2A2F56'}
                                fontSize={'14px'}
                              >
                                {segment.name.trim().length > 0 ? (
                                  <Text
                                    style={{
                                      maxWidth: '800px',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    {segment.name}
                                  </Text>
                                ) : (
                                  <Text color={'gray.500'} fontSize={'14px'}>
                                    Unnamed
                                  </Text>
                                )}
                              </Link>
                            </Td>
                            <Td width={'10%'}>
                              <Image
                                width={'16px'}
                                height={'16px'}
                                src="/ic_delete.png"
                                ml={'20px'}
                                cursor={'pointer'}
                                onClick={() => handleDeleteSegment(segment.id)}
                              />
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                )
              )}
            </Box>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default SegmentList;
