import { useState, useEffect } from 'react';
import { Flex, Image, Link, Text, Select } from '@chakra-ui/react';
import {
  useToken,
  UserInfo,
  getUserTokenByAccount,
  judgeUserInfo,
} from '../../services/auth';
import { useHistory, Link as RouterDomLink } from 'react-router-dom';
import { EntitiesEnum } from '../../services/queryBuilder';
import { PAGE_INDEX_HOME, PAGE_INDEX_SEGMENTS } from '../../utils/constants';
import { checkIfStringEndsWithNumber } from '../../utils/utils';

type SideBarProps = {
  removeToken: () => void;
  pageIndex: number;
  setToken: (userInfo: UserInfo | null) => void;
  setIsLoading: (isLoading: boolean) => void;
};

const SideBar = ({
  removeToken,
  pageIndex,
  setToken,
  setIsLoading,
}: SideBarProps) => {
  const { userInfo } = useToken();
  const [currentUserInfo, setCurrentUserInfo] = useState<UserInfo | null>(
    userInfo,
  );
  const history = useHistory();

  const handleChange = async (id: string) => {
    if (currentUserInfo && currentUserInfo.accounts) {
      let currentUserAccount = currentUserInfo.currentAccount;
      currentUserInfo.accounts.forEach((e) => {
        if (e.id == id) {
          currentUserAccount = e;
        }
      });
      if (currentUserAccount) {
        setIsLoading(true);
        try {
          const token = await getUserTokenByAccount(
            currentUserInfo.ticket,
            currentUserAccount.account,
          );

          const newUserInfo: UserInfo = {
            ...currentUserInfo,
            currentAccount: currentUserAccount,
            token,
          };
          setToken(newUserInfo);
          setCurrentUserInfo(newUserInfo);

          history.push(`/home`);
          window.location.reload();
        } catch (error) {
          if (error && String(error).includes('Authentication failed')) {
            removeToken();
          }
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        if (!judgeUserInfo()) {
          const currentURL = window.location.href;
          if (checkIfStringEndsWithNumber(currentURL)) {
            history.push(`/home`);
          }
          window.location.reload();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <Flex flexDir={'column'} width={'230px'} minWidth={'230px'}>
      {currentUserInfo?.accounts && currentUserInfo?.currentAccount && (
        <Flex alignItems={'center'} ml="24px" mt="12px">
          <svg
            color={'white'}
            width="16"
            height="16"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 0.25C1.0335 0.25 0.25 1.0335 0.25 2C0.25 2.9665 1.0335 3.75 2 3.75C2.9665 3.75 3.75 2.9665 3.75 2C3.75 1.0335 2.9665 0.25 2 0.25Z"
              fill="currentColor"
            ></path>
            <path
              d="M9 0.25C8.0335 0.25 7.25 1.0335 7.25 2C7.25 2.9665 8.0335 3.75 9 3.75C9.9665 3.75 10.75 2.9665 10.75 2C10.75 1.0335 9.9665 0.25 9 0.25Z"
              fill="currentColor"
            ></path>
            <path
              d="M14.25 2C14.25 1.0335 15.0335 0.25 16 0.25C16.9665 0.25 17.75 1.0335 17.75 2C17.75 2.9665 16.9665 3.75 16 3.75C15.0335 3.75 14.25 2.9665 14.25 2Z"
              fill="currentColor"
            ></path>
            <path
              d="M0.25 9C0.25 8.0335 1.0335 7.25 2 7.25C2.9665 7.25 3.75 8.0335 3.75 9C3.75 9.9665 2.9665 10.75 2 10.75C1.0335 10.75 0.25 9.9665 0.25 9Z"
              fill="currentColor"
            ></path>
            <path
              d="M7.25 9C7.25 8.0335 8.0335 7.25 9 7.25C9.9665 7.25 10.75 8.0335 10.75 9C10.75 9.9665 9.9665 10.75 9 10.75C8.0335 10.75 7.25 9.9665 7.25 9Z"
              fill="currentColor"
            ></path>
            <path
              d="M16 7.25C15.0335 7.25 14.25 8.0335 14.25 9C14.25 9.9665 15.0335 10.75 16 10.75C16.9665 10.75 17.75 9.9665 17.75 9C17.75 8.0335 16.9665 7.25 16 7.25Z"
              fill="currentColor"
            ></path>
            <path
              d="M0.25 16C0.25 15.0335 1.0335 14.25 2 14.25C2.9665 14.25 3.75 15.0335 3.75 16C3.75 16.9665 2.9665 17.75 2 17.75C1.0335 17.75 0.25 16.9665 0.25 16Z"
              fill="currentColor"
            ></path>
            <path
              d="M7.25 16C7.25 15.0335 8.0335 14.25 9 14.25C9.9665 14.25 10.75 15.0335 10.75 16C10.75 16.9665 9.9665 17.75 9 17.75C8.0335 17.75 7.25 16.9665 7.25 16Z"
              fill="currentColor"
            ></path>
            <path
              d="M16 14.25C15.0335 14.25 14.25 15.0335 14.25 16C14.25 16.9665 15.0335 17.75 16 17.75C16.9665 17.75 17.75 16.9665 17.75 16C17.75 15.0335 16.9665 14.25 16 14.25Z"
              fill="currentColor"
            ></path>
          </svg>

          <Select
            backgroundColor={'#505268'}
            borderColor={'#505268'}
            color={'#DDE0FB'}
            variant="offset"
            cursor={'pointer'}
            onChange={(e) => handleChange(e.target.value)}
            value={currentUserInfo.currentAccount.id}
            textAlign="left"
            sx={{
              paddingLeft: '6px',
              fontFamily: 'Beatrice',
              fontSize: 12,
              fontWeight: '400',
              lineSpace: '-0.006em',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {Object.values(currentUserInfo?.accounts).map((a) => (
              <option key={a.id} value={a.id}>
                {a.name}
              </option>
            ))}
          </Select>
        </Flex>
      )}
      <Link
        ml={'24px'}
        mt={'60px'}
        color={pageIndex === PAGE_INDEX_HOME ? 'white' : '#DDE0FB'}
        fontSize={20}
        fontFamily={'Beatrice'}
        fontWeight={500}
        _focus={{ boxShadow: 'none' }}
        textDecor={pageIndex === PAGE_INDEX_HOME ? 'underline' : ''}
        textAlign={'left'}
        as={RouterDomLink}
        to={`/${EntitiesEnum.HOME}`}
      >
        Home
      </Link>
      <Link
        ml={'24px'}
        mt={'16px'}
        color={pageIndex === PAGE_INDEX_SEGMENTS ? 'white' : '#DDE0FB'}
        fontSize={20}
        fontFamily={'Beatrice'}
        fontWeight={500}
        _focus={{ boxShadow: 'none' }}
        textDecor={pageIndex === PAGE_INDEX_SEGMENTS ? 'underline' : ''}
        textAlign={'left'}
        as={RouterDomLink}
        to={`/segs/${EntitiesEnum.CUSTOMERS}`}
      >
        Queries
      </Link>
      {/* <Link
        ml={"24px"}
        mt={"12px"}
        color={selPageIndex == PAGE_INDEX_ANALYTICS ? "white" : "#DDE0FB"}
        fontSize={20}
        fontFamily={"Beatrice"}
        fontWeight={500}
        _focus={{ boxShadow: "none" }}
        textDecor={selPageIndex == PAGE_INDEX_ANALYTICS ? "underline" : ""}
        textAlign={"left"}
      >
        Analytics
      </Link>
      <Link
        ml={"24px"}
        mt={"12px"}
        color={selPageIndex == PAGE_INDEX_DASHBOARDS ? "white" : "#DDE0FB"}
        fontSize={20}
        fontFamily={"Beatrice"}
        fontWeight={500}
        _focus={{ boxShadow: "none" }}
        textDecor={selPageIndex == PAGE_INDEX_DASHBOARDS ? "underline" : ""}
        textAlign={"left"}
      >
        Dashboards
      </Link>
      <Link
        ml={"24px"}
        mt={"12px"}
        color={selPageIndex == PAGE_INDEX_SYNC ? "white" : "#DDE0FB"}
        fontSize={20}
        fontFamily={"Beatrice"}
        fontWeight={500}
        _focus={{ boxShadow: "none" }}
        textDecor={selPageIndex == PAGE_INDEX_SYNC ? "underline" : ""}
        textAlign={"left"}
      >
        Sync
      </Link> */}
      {/* {currentUserInfo &&
        (currentUserInfo?.profile.email.endsWith('@offsetpartners.com') ||
          currentUserInfo?.profile.email.endsWith('@interactivelabs.co')) && (
          <Link
            ml={'24px'}
            mt={'16px'}
            color={pageIndex === PAGE_INDEX_TESTS ? 'white' : '#DDE0FB'}
            fontSize={20}
            fontFamily={'Beatrice'}
            fontWeight={500}
            _focus={{ boxShadow: 'none' }}
            textDecor={pageIndex === PAGE_INDEX_TESTS ? 'underline' : ''}
            textAlign={'left'}
            as={RouterDomLink}
            to={`/tests/${EntitiesEnum.CUSTOMERS}`}
          >
            Tests
          </Link>
        )} */}
      {/* <Link
        ml={"24px"}
        mt={"12px"}
        color={pageIndex === PAGE_RECOMMENDATION ? "white" : "#DDE0FB"}
        fontSize={20}
        fontFamily={"Beatrice"}
        _focus={{ boxShadow: "none" }}
        textDecor={pageIndex === PAGE_RECOMMENDATION ? "underline" : ""}
        textAlign={"left"}
        as={RouterDomLink}
        to={`/recommendations`}
      >
        Recommendation
      </Link> */}
      <Flex
        flexDirection={'column'}
        alignItems={'start'}
        justifyContent={'end'}
        ml={'24px'}
        mb={'32px'}
        h={'100%'}
      >
        <Image src="/offset-logo-white.png" w={'80px'} />
        <Text mt={3} fontSize={'11px'} color={'#dde0fb'}>
          © {new Date().getFullYear()}
        </Text>
        <Link
          mt={0.5}
          fontSize={'11px'}
          color={'#dde0fb'}
          _focus={{ boxShadow: 'none' }}
          href={'https://www.offsetpartners.com/terms/'}
          target={'_blank'}
        >
          Terms & Conditions
        </Link>
        <Link
          mt={0.5}
          fontSize={'11px'}
          color={'#dde0fb'}
          _focus={{ boxShadow: 'none' }}
          href={'https://www.offsetpartners.com/privacy/'}
          target={'_blank'}
        >
          Privacy Policy
        </Link>
        <Link
          mt={0.5}
          fontSize={'11px'}
          color={'#dde0fb'}
          _focus={{ boxShadow: 'none' }}
          href={'mailto:support@offsetpartners.com'}
          target={'_blank'}
        >
          Support
        </Link>
      </Flex>
    </Flex>
  );
};

export default SideBar;
